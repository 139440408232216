import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function sinalAtivoList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers

  const perPage = ref(10)
  const totalCount = ref(0)
  const currentPage = ref(1)
  const sortBy = ref('id,desc')
  const searchQuery = ref('')
  const isSortDirDesc = ref(true)
  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCount.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchList = (ctx, callback) => {
    store
      .dispatch('notificacao/getNotificacoes', {
        size: perPage.value,
        page: currentPage.value - 1,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        query: encodeURI(searchQuery.value),
        statusSinal: 0,
      })
      .then(response => {
        const { content, totalElements } = response.data

        callback(content)
        totalCount.value = totalElements
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao recuperar a lista.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchList,
    perPage,
    currentPage,
    totalCount,
    dataMeta,
    sortBy,
    isSortDirDesc,
    refListTable,
    searchQuery,
    refetchData,
  }
}
