<template>
  <div>
    <b-card
      no-body
    >
      <b-card-header class="pb-50">
        <h5>
          {{ $t('notificacao.header') }}
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('notificacao.titulo') }}</label>
            <b-form-input
              v-model="searchQuery"
              class="form-control"
            />
          </b-col>
          <b-col
            cols="12"
            md="2"
            class="mb-md-0 mb-2"
          >
            <label style="visibility:hidden">{{ $t('notificacao.labelBtnPesquisa') }}</label>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="form-control"
              @click.prevent="buscarFiltro"
            >
              {{ $t('notificacao.pesquisaBtn') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
    >
      <b-table
        ref="refListTable"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum registro encontrado"
        :sort-desc.sync="isSortDirDesc"
        striped
      >

        <template #cell(dataCriacao)="data">
          {{ formatDateTimeBR(data.value) }}
        </template>

        <!-- Column: Role -->
        <template #cell(icone)="data">
          <b-avatar
            size="32"
            :variant="getEstiloNotificacao(data.item.estilo)"
          >
            <feather-icon
              :icon="getIconNotificacao(data.item.estilo)"
              size="18"
            />
          </b-avatar>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('notificacao.alertTextMuted', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BPagination, BRow, BCol, BAvatar, BFormInput, BCardHeader, BCardBody, BButton,
} from 'bootstrap-vue'
import { formatDateTimeBR } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import Ripple from 'vue-ripple-directive'
import notificacaoList from './notificacaoList'

export default {

  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BCardHeader,
    BCardBody,
    BButton,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  setup() {
    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      searchQuery,
    } = notificacaoList()

    return {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateTimeBR,
      searchQuery,
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'icone', label: '' },
        { key: 'titulo', label: this.$i18n.t('notificacao.titulo') },
        { key: 'mensagem', label: this.$i18n.t('notificacao.mensagem') },
        { key: 'dataCriacao', label: this.$i18n.t('notificacao.dataCriacao') },
      ]
    },
  },
  methods: {

    buscarFiltro() {
      this.refetchData()
    },

    /* eslint no-else-return: "error" */
    getIconNotificacao(estilo) {
      if (estilo === 'success') {
        return 'CheckIcon'
      } else if (estilo === 'danger') {
        return 'XIcon'
      } else if (estilo === 'warning') {
        return 'AlertTriangleIcon'
      }
      return 'MessageCircleIcon'
    },

    getEstiloNotificacao(estilo) {
      if (estilo === 'success') {
        return 'light-success'
      } else if (estilo === 'danger') {
        return 'light-danger'
      } else if (estilo === 'warning') {
        return 'light-warning'
      }
      return 'light-info'
    },
  },
}
</script>
